import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { SocialProps, Socials } from '@marty-js/design/src/molecules/socials';
import { PostItemLine } from '@marty-js/design/src/molecules/post-item-line';
import { Newsletter } from '@marty-js/design/src/molecules/newsletter';
import { useListItemsQuery } from '@marty-js/api-sdk/services/items';
import { Ad } from '../../../ads/ad';
import { Container, MainContent, SideContent } from '../../../atoms/container';
import type { LayoutItemProps } from '../types';
import { Image } from '../../../atoms/image/image';
import { PostsList } from '../../../layout/post/posts-list';
import type { FluxItem } from '../../../types';
import { EditoContent } from '../../../article-processor/edito-content';
import type { Cell } from '../../../article-processor/types';
import { formatDate } from '../../../atoms/date/utils';
import { SideContentSticky } from '../../../atoms/grid-container';
import { useInfiniteScroll } from '../../../utils/infinite-scroll.hooks';
import { Spinner } from '../../../utils/spinner';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const StyledImage = styled(Image)`
  border-radius: 160px;
`;

export type AuthorData = {
  mainArticle: {
    articleJson: Cell[];
  };
  lastArticles: {
    itemList: FluxItem[];
  };
};

export const AuthorItem: React.FC<LayoutItemProps<AuthorData>> = ({ item, data }) => {
  const t = useTranslation();
  const socials = item?.mainDataSheet?.groups.find((group) => group.shortSlug === 'social_network');

  const twitter = socials?.attributes.find((attribute) => attribute.shortSlug === 'twitter') as SocialProps | null;
  const linkedin = socials?.attributes.find((attribute) => attribute.shortSlug === 'linkedin') as SocialProps | null;

  const { items, fetchMoreData, loading } = useListItemsQuery({
    variables: { id: item.id, from: 0, size: 0 },
  });

  const detectorRef = useInfiniteScroll(() => {
    const nextPagesItemsSize = items?.length || 0;
    const mainFluxItemsSize = data.lastArticles.itemList.length || 0;

    fetchMoreData({
      variables: {
        id: item.id,
        from: nextPagesItemsSize + mainFluxItemsSize,
        size: mainFluxItemsSize,
      },
    });
  });

  return (
    <>
      <Ad className="Billboard_1" desktopOnly noBackground megaban />
      <Container>
        <MainContent>
          <HeadingOne>{item.title}</HeadingOne>
          <Socials socials={[twitter, linkedin]} />
          <ImageContainer>
            <StyledImage
              imageId={item.imageId}
              mobileWidth={237}
              mobileHeight={237}
              desktopWidth={237}
              desktopHeight={237}
              alt={item.title}
            />
          </ImageContainer>
          <HeadingTwo className="mod-medium">{t('design.itemAuthor.presentation')}</HeadingTwo>
          <EditoContent articleJson={data.mainArticle.articleJson} />

          {data.lastArticles?.itemList && (
            <>
              <HeadingTwo className="mod-medium">{t('design.itemAuthor.latestNewsTitle')}</HeadingTwo>
              <PostsList>
                {data.lastArticles?.itemList?.map((it) => (
                  <PostItemLine
                    id={it.id}
                    key={it.id}
                    title={it.title}
                    image={{ imageId: it.imageId, alt: it.title }}
                    date={formatDate(it.republishedAt, 'dd MMMM yyyy', t('locale'))}
                    link={{ href: it.url }}
                  />
                ))}
                {items?.map((it: any) => (
                  <PostItemLine
                    id={it.id}
                    key={it.id}
                    title={it.title}
                    image={{ imageId: it.imageId, alt: it.title }}
                    date={formatDate(it.republishedAt, 'dd MMMM yyyy', t('locale'))}
                    link={{ href: it.url }}
                  />
                ))}
              </PostsList>
              {loading && (
                <div>
                  <Spinner width={50} height={50} color="black" />
                </div>
              )}
              <div ref={detectorRef} aria-hidden />
            </>
          )}
        </MainContent>
        <SideContent>
          <Ad className="HalfpageAd_1" desktopOnly />
          <Newsletter />
          <SideContentSticky>
            <Ad className="HalfpageAd_2" desktopOnly />
          </SideContentSticky>
        </SideContent>
      </Container>
    </>
  );
};

export default AuthorItem;
