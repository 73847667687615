import React from 'react';

import styled, { StyledComponent } from 'styled-components';
import { Cta, CtaProps } from '../cta';

type CtaWrapperProps = { nbCol: number; alignment: string };
export type Props = CtaProps & CtaWrapperProps & { as?: StyledComponent<'a', any>; buttonSize: string };

const getPaddingSize = (buttonSize: string) => {
  switch (buttonSize) {
    case 'large':
      return '10px 13px;';
    default:
      return '8px 10px;';
  }
};

const getAlignement = (alignment: string) => {
  switch (alignment) {
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    case 'left':
    default:
      return 'flex-start';
  }
};

const CtaButton = styled(Cta)<{ buttonSize: string }>`
  margin: var(--spacer-s-fluid) 0 var(--spacer-fluid);
  padding: ${(props) => getPaddingSize(props.buttonSize)};
  line-height: 1;

  && {
    color: ${(props) => props.theme.palette.white};
    text-decoration: none;
  }

  font-size: ${(props) => (props.buttonSize === 'large' ? '18px' : '16px')};
  line-height: 1.62;

  background: ${(props) => props.theme.palette.red};
  border: solid 2px ${(props) => props.theme.palette.red};
  border-radius: 6px;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background: ${(props) => props.theme.palette.darkRed};
    border: solid 2px ${(props) => props.theme.palette.darkRed};
  }
`;

const CtaWrapper = styled.div<CtaWrapperProps>`
  grid-column-end: span ${(props) => props.nbCol};
  display: flex;
  width: 100%;
  justify-content: ${(props) => getAlignement(props.alignment)};
`;

const ArticleCta = ({
  nbCol,
  alignment,
  onClick,
  as,
  buttonSize,
  referer,
  href,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <CtaWrapper data-testid="cta-button-wrapper" nbCol={nbCol} alignment={alignment}>
      <CtaButton
        onClick={onClick}
        as={as}
        data-testid="cta-button"
        buttonSize={buttonSize}
        referer={referer}
        href={href}
      >
        {children}
      </CtaButton>
    </CtaWrapper>
  );
};

export default ArticleCta;
