import React from 'react';
import { ReactCompareSlider } from 'react-compare-slider';
import styled from 'styled-components';
import { ImageComponent } from '../utils/component';

const StyledImage = styled(ImageComponent)`
  width: 100%;
  height: auto;
`;

interface Props {
  firstImageId: string;
  secondImageId: string;
  mobileWidth: number;
  mobileHeight: number;
  desktopWidth: number;
  desktopHeight: number;
  alt: string;
  firstTitle: string;
  secondTitle: string;
  firstExtension?: string;
  secondExtension?: string;
}
export function ImageCompare({
  firstImageId,
  secondImageId,
  mobileWidth,
  mobileHeight,
  desktopWidth,
  desktopHeight,
  alt,
  firstExtension,
  secondExtension,
  firstTitle,
  secondTitle,
}: Props) {
  return (
    <ReactCompareSlider
      itemOne={
        <StyledImage
          imageId={firstImageId}
          desktopWidth={desktopWidth}
          desktopHeight={desktopHeight}
          mobileWidth={mobileWidth}
          mobileHeight={mobileHeight}
          extension={firstExtension}
          alt={alt}
          title={firstTitle}
        />
      }
      itemTwo={
        <StyledImage
          imageId={secondImageId}
          desktopWidth={desktopWidth}
          desktopHeight={desktopHeight}
          mobileWidth={mobileWidth}
          mobileHeight={mobileHeight}
          extension={secondExtension}
          alt={alt}
          title={secondTitle}
        />
      }
    />
  );
}
