import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export interface TableProps {
  data?: string[][];
}

const TabWrapper = styled.div`
  margin: 30px 0 60px;
  max-width: calc(100vw - 40px);
  overflow: auto;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      max-width: initial;
    `,
  )}
`;

const Tab = styled.table`
  border-spacing: 0;
  font-size: 14px;
  margin: 0 0 15px;
  overflow-y: auto;
  padding-bottom: 8px;

  td {
    border-spacing: 0;
    border-right: solid 1px #e5e8ee;
    line-height: 20px;
    min-width: 130px;
    padding: 7px 10px 7px 25px;

    ${mq.gte(
      DEVICE_SIZE.LARGE,
      css`
        padding: 10px 10px 10px 30px;
      `,
    )}

    &:first-of-type {
      background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
      background-clip: padding-box !important;
      border-bottom: solid 5px transparent;
      border-radius: 9px;
      border-right: none;
      border-top: solid 5px transparent;
      color: @darkblue;
      padding: 10px 15px;
      position: sticky;
      left: 0;
    }

    &:last-of-type {
      border-right: none;
    }

    &:nth-of-type(odd) {
    }
  }
`;

const TabHead = styled.thead`
  td,
  th {
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.typography.primaryFont};
    font-size: 16px;
    font-weight: 600;
    padding: 10px 10px 10px 25px;

    ${mq.gte(
      DEVICE_SIZE.LARGE,
      css`
        padding: 10px 10px 10px 30px;
      `,
    )}

    &:nth-of-type(odd) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:nth-of-type(1) {
      background: none;
    }
  }

  tr {
    &:last-of-type td {
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

const TabBody = styled.tbody`
  tr {
    &:last-of-type td {
      border-bottom: solid 4px transparent;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const Table = (props: TableProps) => {
  const { data } = props;

  return (
    <TabWrapper>
      {data ? (
        <Tab>
          <TabHead>
            <tr key="0">
              {data[0]?.map((field, keyField) => (
                <td key={keyField}>{field}</td>
              ))}
            </tr>
          </TabHead>
          <TabBody>
            {data.slice(1).map((row, keyRow) => (
              <tr key={keyRow + 1}>
                {row?.map((field, keyField) => (
                  <td key={keyField}>{field}</td>
                ))}
              </tr>
            ))}
          </TabBody>
        </Tab>
      ) : null}
    </TabWrapper>
  );
};
