import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const containerBackgroundTheme = ['light', 'contrast', 'transparent', 'flashy'];

const Wrapper = styled.div<{
  backgroundTheme?: typeof containerBackgroundTheme[number];
}>`
  margin: var(--spacer-s-fluid) 0 var(--spacer-fluid);
  padding: var(--spacer-fluid);
  border: solid 1px #ddd;
  border-radius: 6px;

  &.transparent {
    background: none;
    border: none;
    padding: 0;
  }

  &.contrast {
    background-color: ${(props) => props.theme.palette.blue};
    border: none;
    color: white;
  }

  &.flashy {
    background-color: ${(props) => props.theme.palette.red};
    border: none;
    color: white;
  }

  .row {
    margin-bottom: 0;
  }
`;
type ContainerArticleProps = {
  className?: string;
  children: ReactNode;
};

const ContainerArticle = ({ className, children }: ContainerArticleProps) => {
  return <Wrapper className={className ?? 'transparent'}>{children}</Wrapper>;
};

export default ContainerArticle;
