import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export interface PanelProps {
  tabs?: string[];
  children?: any;
}

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

const Tabs = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 0 0 16px;
  max-width: calc(100vw - 40px);
  overflow-y: auto;
  padding: 0 0 12px;
  white-space: nowrap;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      max-width: initial;
      padding: 0 0 16px;
    `,
  )}
`;

const TabItem = styled.div`
  cursor: pointer;
  font-weight: bold;
  padding: 12px 22px;
  transition: all 0.3s;

  &.mod-is-active {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 4px;
    color: white;
  }
`;

const Tables = styled.div``;

export const Panel = ({ ...props }: React.PropsWithChildren<PanelProps>) => {
  const { tabs, children } = props;

  const ref = useRef(null);
  const [tabSelected, setTabSelected] = useState(0);

  const selectTabFromIndex = (index: Number) => {
    if (!ref.current) {
      return;
    }

    [...ref.current.children].map((table: any, i: Number) => {
      table.style.display = index === i ? 'flex' : 'none';

      return table;
    });
  };

  const selectTab = (event: any) => {
    const element = event.target;
    const index = [...element.parentNode.children].indexOf(element);

    setTabSelected(index);
    selectTabFromIndex(index);
  };

  useEffect(() => {
    selectTabFromIndex(0);
  }, [ref]);

  return (
    <PanelWrapper>
      <Tabs>
        {tabs.map((item, i) => (
          <TabItem key={i} className={tabSelected === i ? 'mod-is-active' : ''} onClick={selectTab}>
            {item}
          </TabItem>
        ))}
      </Tabs>
      <Tables ref={ref}>{children}</Tables>
    </PanelWrapper>
  );
};
