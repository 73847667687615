import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export interface LiveProps {
  fulldate?: string;
  time?: string;
  title?: string;
  anchor?: string;
  children?: any;
}

const LiveWrapper = styled.div``;

const Header = styled.div`
  border-left: solid 1px ${(props) => props.theme.colors.primary};
  position: relative;
  margin-left: 20px;
  padding-left: 20px;

  &::after {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    content: '';
    height: 20px;
    left: -10px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin-left: 10px;
    `,
  )}
`;

const Time = styled.span`
  color: ${(props) => props.theme.colors.primary};
  display: block;
  font-size: 18px;
  padding-bottom: 4px;
`;

const HeaderTitle = styled.h3`
  margin: 0;
`;

const Title = styled.span`
  display: block;
  padding-bottom: 8px;
`;

const Content = styled.div`
  border-left: solid 1px #e4002a;
  margin-left: 20px;
  padding-left: 20px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin-left: 10px;
    `,
  )}
`;

export const Live = ({ ...props }: React.PropsWithChildren<LiveProps>) => {
  const { fulldate, time, title, anchor, children } = props;

  return (
    <LiveWrapper data-fulldate={fulldate} id={anchor}>
      <Header>
        <HeaderTitle>
          <Time>{time}</Time>
          <Title>{title}</Title>
        </HeaderTitle>
      </Header>
      <Content>{children}</Content>
    </LiveWrapper>
  );
};
