import React from 'react';
import styled from 'styled-components';
import Twitter from '../icons/twitter';
import { useThemeSwitcher } from '../utils/theme-switcher';
import Linkedin from '../icons/linkedin';
import { useLinkComponent } from '../utils/component';

export type SocialProps = {
  shortSlug: string | null;
  value: string | null;
  name: string | null;
};

type BorderedIconProps = {
  socials: SocialProps[];
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
`;

const Bordered = styled.span`
  background: ${(props) => (props.theme.isDark ? 'none' : '#F2F4F6')};
  border: solid 2px #dddddd;
  border-radius: 100%;
  display: inline-flex;
  padding: 11px;
  transition: border 0.2s ease-in;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const Socials = ({ socials }: BorderedIconProps) => {
  const { currentTheme } = useThemeSwitcher();
  const LinkComponent = useLinkComponent();

  return (
    <Wrapper>
      {socials.map((social: SocialProps, index: number) => {
        if (social?.value) {
          switch (social?.shortSlug) {
            case 'twitter':
              return (
                <LinkComponent
                  href={`https://twitter.com/${social.value}`}
                  key={index}
                  openInNewWindow
                  rel={['nofollow', 'noopener']}
                >
                  <Bordered>
                    <Twitter width={20} height={20} color={currentTheme.isDark ? 'white' : 'black'} />
                  </Bordered>
                </LinkComponent>
              );
            case 'linkedin':
              return (
                <LinkComponent
                  href={`https://www.linkedin.com/in/${social.value}`}
                  key={index}
                  openInNewWindow
                  rel={['nofollow', 'noopener']}
                >
                  <Bordered>
                    <Linkedin width={20} height={20} color={currentTheme.isDark ? 'white' : 'black'} />
                  </Bordered>
                </LinkComponent>
              );
            default:
              return null;
          }
        }

        return null;
      })}
    </Wrapper>
  );
};
