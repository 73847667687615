import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ImageCompare } from '../../atoms/imageCompare';
import { ImageComponent, useLinkComponent } from '../../utils/component';

const StyledImage = styled(ImageComponent)`
  width: 100%;
  height: auto;
`;

interface Props {
  id: string;
  idCompare?: string;
  compare?: boolean;
  width: number;
  height: number;
  extension?: string;
  extensionCompare?: string;
  title: string;
  titleCompare?: string;
  href?: string;
  rel?: string[];
  openInNewWindow?: boolean;
  caption?: string;
  handlerClick?: () => void;
}

const ImageLegend = styled.figcaption`
  text-align: center;
  line-height: 1.5em;
  margin: var(--spacer-xs) 0 0 !important;
  font-size: 12px;
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.black)};
`;

export function ImageArticle({
  id,
  idCompare,
  compare,
  width,
  height,
  extension,
  extensionCompare,
  title,
  titleCompare,
  href,
  rel,
  openInNewWindow,
  caption,
  handlerClick,
}: Props) {
  const NavLink = useLinkComponent();

  const image = useMemo(
    () =>
      compare ? (
        <ImageCompare
          firstImageId={id}
          secondImageId={idCompare}
          mobileWidth={width}
          mobileHeight={height}
          desktopWidth={width}
          desktopHeight={height}
          firstExtension={extension}
          secondExtension={extensionCompare}
          firstTitle={title}
          secondTitle={titleCompare}
          alt={caption ?? title}
        />
      ) : (
        <StyledImage
          imageId={id}
          mobileWidth={width || 600}
          mobileHeight={height || 600}
          desktopWidth={width || 600}
          desktopHeight={height || 600}
          alt={caption ?? title}
          title={title}
          maxWidth={width || 600}
          extension={extension}
        />
      ),
    [compare, id, idCompare, width, height, extension, extensionCompare, title, titleCompare, caption],
  );

  return (
    <>
      {href ? (
        <NavLink href={href} rel={rel} openInNewWindow={openInNewWindow}>
          {image}
        </NavLink>
      ) : (
        <span
          onClick={compare ? null : handlerClick}
          onKeyDown={compare ? null : handlerClick}
          onDoubleClick={compare ? handlerClick : null}
          role="presentation"
        >
          {image}
        </span>
      )}
      {caption ? <ImageLegend data-testid="image-clubic-caption">{caption}</ImageLegend> : null}
    </>
  );
}

export default ImageArticle;
