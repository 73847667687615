import React from 'react';

import styled from 'styled-components';
import { useTranslation } from '../../utils/translation';

type Props = { nbCol: number; designation: string; href: string; title: string };

const Wrapper = styled.div<{ nbCol: number }>`
  grid-column-end: span ${(props) => props.nbCol};
  background-color: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
  color: ${(props) => props.theme.backgroundTheme.contrast.textColor};
  padding: 20px;
  margin: 16px 0;

  a {
    color: ${(props) => props.theme.backgroundTheme.contrast.linkColor};
  }
`;

const ReadMore = ({ nbCol, designation, href, title }: React.PropsWithChildren<Props>) => {
  const t = useTranslation();

  return (
    <Wrapper nbCol={nbCol}>
      <b>{designation || t('design.readMoreBloc.read_more_label')} :</b>
      <br />
      <a href={href}>{title}</a>
    </Wrapper>
  );
};

export default ReadMore;
